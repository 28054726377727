

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Peyda" !important;

}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  text-align: right !important;
  direction: rtl;
}

p , h1 , h2  ,h3 {
  font-family: "Peyda" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Peyda";
  src: url(/public/Peyda/02-Farsi_Namerals/TTF/PeydaFaNum-Regular.ttf);
}