@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-brand{
    background-color: #0D0D0D;
    border-radius: 10px;
    height: 48px;
    padding: 8px 22px 8px 22px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.btn-brand-outline{
    border:1px solid #0D0D0D;
    border-radius: 10px;
    height: 48px;
    padding: 8px 22px 8px 22px;
    color: #0D0D0D;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.three_line{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus , .image-gallery-content .image-gallery-slide .image-gallery-image , .image-gallery-thumbnail .image-gallery-thumbnail-image{
    border-radius: 10px;
}

.logo-center{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50% , -50%);
}

.header-line::after{
    content: "";
    position: absolute;
    right: 0;
    width: 43%;
    height: 1px;
    background-color: #747878;
    bottom: 0;
}
.header-line::before{
    content: "";
    position: absolute;
    left: 0;
    width: 43%;
    height: 1px;
    background-color: #747878;
    bottom: 0;
}
.border-brand {
    border: 0.5px solid #C4C7C7;
    border-radius: 10px;
}
.on-border{
    position: absolute;
    top: -25px;
    transform: translateY(50%);
    padding: 0 7px;
    background-color: #fff;
    width: fit-content;
    right: 13px;
}

.on-border-left{
    position: absolute;
    top: -25px;
    transform: translateY(50%);
    padding: 0 7px;
    background-color: #fff;
    width: fit-content;
    left: 13px;
}

.overflow-y-scroll::-webkit-scrollbar{
    width: 5px;
    left: -5%;
}

.overflow-y-scroll::-webkit-scrollbar-track {
    background: #ffffff;
}

.overflow-y-scroll::-webkit-scrollbar-thumb {
    background: #000;
}



.title{
    font-size: 36px;
    font-weight: 700;
    color: #0D0D0D;
}
.desc{
    text-align: justify;
    margin-top: 15px;
}
.line-x::after{
    content: "";
    position: absolute;
    right: 0;
    width: 35%;
    height: 1px;
    background-color: #747878;
    top: 50%;
    transform: translate(0 , -50%);
}
.line-x::before{
    content: "";
    position: absolute;
    left: 0;
    width: 35%;
    height: 1px;
    background-color: #747878;
    top: 50%;
    transform: translate(0 , -50%);
}
.yellow-circle::after{
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #FFA800;
    position: absolute;
    right: -6px;
    top: 3%;
}
.yellow-circle-small::after{
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FFA800;
    position: absolute;
    right: -12px;
    top: 37%;
    z-index: 5;
}

.bg_gradiant{
    background: linear-gradient(180deg, #00000010 0%, rgba(0, 0, 0, 1) 100%);
    border-radius: 10px;

}

@media screen and (min-width : 1376px) {
    .container_1 {
        max-width: 85%;
    }
  
}

@media screen and (min-width : 1024px) and (max-width : 1376px){
    .container_1 {
        max-width: 90%;
    }

    .btn-brand{
        background-color: #0D0D0D;
        border-radius: 10px;
        height: 48px;
        padding: 4px 11px 4px 11px;
        font-size: 14px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    
    .btn-brand-outline{
        border:1px solid #0D0D0D;
        border-radius: 10px;
        height: 48px;
        padding: 4px 11px 4px 11px;
        font-size: 14px;
        color: #0D0D0D;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
    
}

.border-b-card::after{
    content: "";
    position: absolute;
    top: 100%;
    width: 75%;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background-color: #8E9192;
}

.balck-circle-small::after{
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #000000;
    position: absolute;
    right: -18px;
    top: 41%;
    z-index: 5;
}
ul {
    list-style: disc;
}

.center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

.hover-text-secendory:hover{
    color: #FFA800 !important;

}
.text-secendory{
    color: #FFA800 !important;
}
.dashed-right::before{
    content: "";
    position: absolute;
    height: 108%;
    width: 1px;
    border-right: 1px dashed #000;
    right: 0;
    top: 3%;
}
.box-contact{
    border-radius: 10px;
    border: 1px solid #000;
    border-right: 8px solid #FFA800;
    display: flex;
    align-items: center;
    height: 50px;
    gap: 7px;
    padding: 0 10px;
}
.on-border-2{
    position: absolute;
    top: -38px;
    transform: translateY(50%);
    padding: 0 7px;
    background-color: #fff;
    width: fit-content;
    right: 13px;
}

.form-brand{
    border: 0.5px solid #A9ACAC;
    border-radius: 10px;
    min-height: 46px;
    padding: 13px;
    font-size: 14px;
    transition: 0.3s all;
}

select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-image: url("../public/Arrow\ -\ Down\ 2.svg");
    background-repeat: no-repeat;
    background-position: 10px 12px;
}

.form-brand:focus{
    transition: 0.3s all;
    outline: none;
    border-color: #000;
}
.form-brand::placeholder{
   color: #A9ACAC;
}
.rounded-checkbox {
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid black;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .rounded-checkbox:checked::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    border-radius: 50%;
    top: 0;
    background-color: #000;
    z-index: 10;
    border: double 1px #fff;
  }